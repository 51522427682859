import { GFPP_IDS, GFPP_ACTIONS, GFPP } from './manifest-commons'
import translations from '../../../utils/translations'
import MULTI_STEP_HELP_IDS from '../../../plugins/multi-step-form/constants/help-ids'
import { getWidgetDesignManifest } from './widget-design-manifest'
import {
  STEP_ROLE,
  THANK_YOU_STEP_ROLE,
  ROLE_FORM,
  LIMIT_SUBMISSIONS_STEP_ROLE,
} from '../../../constants/roles'
import { AppStateObject } from '../app-state/app-state-builder'

export const createMultiStepManifest = ({
  isTopPremium,
  plugins,
  isAscendToPremiumEnabled,
}: AppStateObject = {}) => {
  // in case of default.
  const isIndependent = !plugins || plugins.length === 1
  return {
    box: {
      showFocusBox: true,
      // isIndependent = false means not override prop and take from default
      ...(isIndependent ? { displayName: translations.t('formBuilder') } : {}),
      behavior: { duplicatable: true },
      gfpp: {
        desktop: {
          ...(isIndependent ? { helpId: MULTI_STEP_HELP_IDS.GFPP } : {}),
          mainAction1: {
            actionId: GFPP_IDS.NEW_FORM_SETTINGS,
            label: translations.t('formSettings.gfppTitle'),
          },
          mainAction2: {
            actionId: GFPP_IDS.MANAGE_STEPS,
            label: translations.t('multiStepForm.manageSteps.gfppTitle'),
          },
          iconButtons: {
            [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
            [GFPP_ACTIONS.ADD]: { actionId: GFPP_IDS.ADD_FIELD },
            [GFPP_ACTIONS.LAYOUT]: { actionId: GFPP_IDS.FORM_LAYOUT },
            ...(isTopPremium || isAscendToPremiumEnabled
              ? {}
              : { [GFPP_ACTIONS.UPGRADE]: GFPP.KEEP_DEFAULT }),
          },
          widgetDesign: getWidgetDesignManifest(true),
        },
        ...(isIndependent
          ? {
              mobile: {
                /* use default configurations */
                helpId: MULTI_STEP_HELP_IDS.MOBILE_GFPP,
              },
            }
          : {}),
      },
    },
    steps: {
      [STEP_ROLE]: {
        relatedToRole: ROLE_FORM,
      },
      [THANK_YOU_STEP_ROLE]: {
        relatedToRole: ROLE_FORM,
      },
      [LIMIT_SUBMISSIONS_STEP_ROLE]: {
        relatedToRole: ROLE_FORM,
      },
    },
  }
}
